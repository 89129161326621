var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-dialog",
    {
      attrs: {
        title: _vm.$t("applications.application-authorization"),
        icon: "mdi-account-check"
      },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: { color: "accent" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.$emit("accept")
                    }
                  }
                },
                [
                  _c("v-icon", { staticClass: "mr-2" }, [
                    _vm._v("mdi-check-circle")
                  ]),
                  _vm._v(_vm._s(_vm.$t("applications.accept")))
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [_vm._v(" " + _vm._s(_vm.$t("applications.authorization-policy")) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }