<template>
    <psi-dialog
        @close="$emit('close')"
        :title="$t('applications.application-authorization')"
        icon="mdi-account-check"
    >
        {{ $t("applications.authorization-policy") }}
        <template v-slot:actions>
            <v-btn color="accent" class="mr-4" @click.stop="$emit('accept')"
                ><v-icon class="mr-2">mdi-check-circle</v-icon
                >{{ $t("applications.accept") }}</v-btn
            >
        </template>
    </psi-dialog>
</template>
<script>
export default {
    name: "applicant-authorize-policy",
    data() {
        return {};
    },
};
</script>

<style scoped>
</style>